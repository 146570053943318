import {isEqualsNotEmptyString} from "../utils/utils";

function toUpperFirstSymbolWord(text: string) {
    text = text.split(' ').map((word: string) => word[0] && word.length > 1 ? word[0].toUpperCase() + word.substring(1, word.length).toLowerCase() : word.toUpperCase()).join(' ');
    text = text.split('-').map((word: string) => word[0] && word.length > 1 ? word[0].toUpperCase() + word.substring(1, word.length).toLowerCase() : word.toUpperCase()).join('-');
    return text;
}

export function phoneFormatting() {
    let inputs: any = document.getElementsByTagName('input');
    const intFirstNameInput = inputs.int_passport_first_name;
    const intLastNameInput = inputs.int_passport_last_name;
    const firstNameInput = inputs.first_name;
    const lastNameInput = inputs.last_name;
    const middleNameInput = inputs.middle_name;
    const codewordInput = inputs.codeword;
    const emailInput = inputs.email;
    if (!inputs || inputs.length === 0) {
        return;
    }
    firstNameInput.addEventListener('input', function (e: any) {
        const target = e.target as any;
        const value = toUpperFirstSymbolWord(target.value.replace(/[^А-Яа-яЁё\-\s]/g, ''));
        if (!target.validity.patternMismatch && value.length === target.value.length) {
            target.setCustomValidity('');
        } else {
            target.reportValidity();
            target.setCustomValidity('Имя кириллицей');
        }
        firstNameInput.value = value;
    });
    lastNameInput.addEventListener('input', function (e: any) {
        const target = e.target as any;
        const value = toUpperFirstSymbolWord(target.value.replace(/[^А-Яа-яЁё\-\s]/g, ''));
        if (!target.validity.patternMismatch && value.length === target.value.length) {
            target.setCustomValidity('');
        } else {
            target.reportValidity();
            target.setCustomValidity('Фамилия кириллицей');
        }
        lastNameInput.value = value;
    });
    middleNameInput.addEventListener('input', function (e: any) {
        const target = e.target as any;
        const value = toUpperFirstSymbolWord(target.value.replace(/[^А-Яа-яЁё\-\s]/g, ''));
        if (!target.validity.patternMismatch && value.length === target.value.length) {
            target.setCustomValidity('');
        } else {
            target.reportValidity();
            target.setCustomValidity('Отчество кириллицей');
        }
        middleNameInput.value = value;
    });
    intFirstNameInput.addEventListener('input', function (e: any) {
        const target = e.target as any;
        const value = target.value.replace(/[^a-zA-Z\-\s]+$/g, '').toUpperCase();
        if (!target.validity.patternMismatch && value.length === target.value.length) {
            target.setCustomValidity('');
        } else {
            target.reportValidity();
            target.setCustomValidity('Имя латиницей');
        }
        intFirstNameInput.value = value;
    });
    intLastNameInput.addEventListener('input', function (e: any) {
        const target = e.target as any;
        const value = target.value.replace(/[^a-zA-Z\-\s]+$/g, '').toUpperCase();
        if (!target.validity.patternMismatch && value.length === target.value.length) {
            target.setCustomValidity('');
        } else {
            target.reportValidity();
            target.setCustomValidity('Фамилия латиницей');
        }
        intLastNameInput.value = value;
    });
    codewordInput.addEventListener('input', function (e: any) {
        const target = e.target as any;
        const value = target.value.toLowerCase();
        if (target.validity.patternMismatch || isEqualsNotEmptyString(value, firstNameInput.value)
            || isEqualsNotEmptyString(value, middleNameInput.value) || isEqualsNotEmptyString(value, lastNameInput.value)) {
            target.reportValidity();
            target.setCustomValidity('Должно содержать только 1 слово из букв и/или дополнительно цифр');
        } else {
            target.setCustomValidity('');
        }
    });
    const intPassportSeries = inputs.int_passport_series;
    intPassportSeries.addEventListener('input', function (e: any) {
        const target = e.target as any;
        const value = target.value.replace(/\D/g, '');
        if (!target.validity.patternMismatch && value.length === target.value.length) {
            target.setCustomValidity('');
        } else {
            target.reportValidity();
            target.setCustomValidity('Серия цифрами');
        }
        intPassportSeries.value = value;
    });
    const intPassportNumber = inputs.int_passport_number;
    intPassportNumber.addEventListener('input', function (e: any) {
        const target = e.target as any;
        const value = target.value.replace(/\D/g, '');
        if (!target.validity.patternMismatch && value.length === target.value.length) {
            target.setCustomValidity('');
        } else {
            target.reportValidity();
            target.setCustomValidity('Номер цифрами');
        }
        intPassportNumber.value = value;
    });
    emailInput.addEventListener('input', function (e: any) {
        const target = e.target as any;
        emailInput.value = target.value.toLowerCase();
    });
    inputs.phone.addEventListener('keydown', function (e: any) {
        if (e.key === 'Delete') {
            const selectionStart = inputs.phone.selectionStart;
            if (selectionStart === 3 || selectionStart === 7 || selectionStart === 10) {
                e.preventDefault();
                let value = inputs.phone.value.substring(0, selectionStart + 1) + inputs.phone.value.substring(selectionStart + 2, inputs.phone.value.length + 1);
                value = value.replace('  ', ' ');
                if (value.lastIndexOf(' ') === value.length - 1) {
                    value = value.substring(0, value.length - 1);
                }
                inputs.phone.value = value;
                inputs.phone.selectionStart = selectionStart;
                inputs.phone.selectionEnd = selectionStart;
            }
        }
    });
    inputs.phone.addEventListener('paste', function (e: any) {
        e.preventDefault();
        let paste = (e.clipboardData || (window as any).clipboardData).getData('text');
        paste = paste.replace(/[^\d]/g, '');
        if (paste.startsWith('7')) {
            paste = paste.substring(1, paste.length);
        }
        let phoneStr = paste.substring(0, 3);
        if (paste.length > 3) {
            phoneStr += ' ' + paste.substring(3, 6);
        }
        if (paste.length > 6) {
            phoneStr += ' ' + paste.substring(6, 8);
        }
        if (paste.length > 8) {
            phoneStr += ' ' + paste.substring(8, 10);
        }
        inputs.phone.value = phoneStr;
    });
    inputs.contact_phone.addEventListener('input', function (e: any) {
        const target = e.target as any;
        let value = e.target.value.replace(/[^\+\d]/g, '').substring(0, 18);
        if (!target.validity.patternMismatch && value.length === target.value.length) {
            target.setCustomValidity('');
        } else {
            target.reportValidity();
            target.setCustomValidity('Номер цифрами');
        }
        inputs.contact_phone.value = value;
    });
    inputs.phone.addEventListener('input', function (e: any) {
        const target = e.target as any;
        let value = e.target.value.replace(/[^\d]/g, '').substring(0, 13);
        if (!target.validity.patternMismatch && value.length === target.value.length) {
            target.setCustomValidity('');
        } else {
            target.reportValidity();
            target.setCustomValidity('Номер цифрами');
        }
        if (value && value.length > 9) {
            inputs.email.focus();
        }
        if (e.target.value) {
            let selectionStart = inputs.phone.selectionStart;
            const initLength = e.target.value.length;
            let phoneStr = value.substring(0, 3);
            if (value.length > 3) {
                phoneStr += ' ' + value.substring(3, 6);
            }
            if (value.length > 6) {
                phoneStr += ' ' + value.substring(6, 8);
            }
            if (value.length > 8) {
                phoneStr += ' ' + value.substring(8, 10);
            }
            if (initLength > selectionStart && selectionStart > 3) {
                selectionStart -= 1;
            }
            if (selectionStart > 3 && (phoneStr.length === 12 || phoneStr.length === 9 || phoneStr.length === 5)) {
                selectionStart += 1;
            }
            inputs.phone.value = phoneStr;
            inputs.phone.selectionStart = selectionStart;
            inputs.phone.selectionEnd = selectionStart;
        }
    });

    const inputsForBlurListener = [intFirstNameInput, intLastNameInput, firstNameInput, middleNameInput, lastNameInput,
        intPassportNumber, intPassportSeries, inputs.phone];
    inputsForBlurListener.forEach(input => input.addEventListener('blur', function (e: any) {
        const target = e.target as any;
        if (!target.validity.patternMismatch && target.validationMessage) {
            target.setCustomValidity('');
        }
    }));
}
