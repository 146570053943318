import {Store} from "../stores/cards";
import sendErrorToBot from "./sendError";

const {REACT_APP_BACKEND_HOST, REACT_APP_BACKEND_KEY,
    REACT_APP_DOCS_HOST, REACT_APP_DOCS_KEY,
    REACT_APP_BANK_KEY} = process.env;

export function createFormData(data: any): FormData {
    let formData = new FormData();
    Object.keys(data).forEach(field => {
        const value = (data as any)[field];
        if (value instanceof Object) {
            Object.keys(value).forEach(f => {
                const vv = value[f];
                if (vv instanceof Object) {
                    if (field === 'attachments') {
                        formData.append(`${field}[${f}]`, vv);
                    } else {
                        Object.keys(vv).forEach(ff => {
                            const vvv = vv[ff];
                            formData.append(`${field}[${f}][${ff}]`, vvv);
                        });
                    }
                } else {
                    formData.append(`${field}[${f}]`, vv);
                }
            });
        } else {
            formData.append(field, value);
        }
    });
    return formData;
}


async function fetcher(url: string, isAuth = true, method = 'GET', isSingle = false, options?: any): Promise<any> {
    try {
        let headers = {};
        if (isAuth) {
            const token = localStorage.getItem('access_token');
            headers = {
                Authorization: `Bearer ${token}`
            }
        }
        const response = await fetch(`${REACT_APP_BACKEND_HOST}/${url}`, {
            headers: {
                ...headers,
                accepts: 'application/json'
            },
            method,
            ...options
        });
        if (response.status >= 400) {
            if (response.status === 401 && !isSingle) {
                const token = localStorage.getItem('client_token');
                if (token) {
                    await auth({token});
                    return await fetcher(url, isAuth, method, true, options);
                }
                const password = localStorage.getItem('manager_pass');
                const login = localStorage.getItem('manager_login');
                if (password && login) {
                    await auth({password, login});
                    return await fetcher(url, isAuth, method, true, options);
                }
                return ;
            }
            await sendErrorToBot(`🟡FOREIGN CARD REGISTRATION ${url}`,
                window.location.href + `\n${response.status}`
                + `${response.status === 401 ? `\nAuth: ${localStorage.getItem('client_token')}` : ''}`);
            const res = await response.json();
            return {
                error: res && res.error ? res.error : response.statusText || response.status || 'error'
            }
        }
        if (url === 'api/client/v1/orders') {
            return response.status === 200 ? true : await response.json();
        }
        return await response.json();
    }
    catch (error) {
        return {
            error: error ? error.toString() : error
        };
    }
}

export async function auth(params: {
    token?: string;
    login?: string;
    password?: string;
}) {
    let url = `${REACT_APP_BACKEND_HOST}/api/client/v1/auth?`;
    if (params.token) {
        url += `client_token=${params.token}`;
    } else {
        url += `login=${params.login}&password=${params.password}`;
    }
    try {
        const response = await fetch(url, {
            headers: {
                accepts: 'application/json',
                Authorization: `Basic ${REACT_APP_BACKEND_KEY}`
            },
            method: 'GET'
        });
        if (response.status >= 400) {
            await sendErrorToBot('🟡FOREIGN CARD AUTH',
                window.location.href + `\n${response.status}`);
            const res = await response.json();
            return {
                error: res && res.error ? res.error : response.statusText || response.status || 'error'
            }
        }
        const res = await response.json();
        if (res && res.result && res.result.access_token) {
            localStorage.setItem('access_token', res.result.access_token);
            return {
                result: true
            }
        }
        return {
            error: JSON.stringify(res.result) || 'Ошибка получения токена'
        };
    }
    catch (error) {
        return {
            error: error
        };
    }
}

export async function getCards(): Promise<any> {
    try {
        const res = await fetcher(`api/client/v1/banks/${REACT_APP_BANK_KEY}`);
        if (res && !res.error && res.result) {
            Store.getInstance().setCards(res.result.cards);
        }
        return res;
    }
    catch (error) {
        return {
            error: error
        };
    }
}

export async function createOrder(body: any): Promise<any> {
    try {
        return await fetcher(`api/client/v1/orders`, true, 'POST', false, {
            body: createFormData(body)
        });
    }
    catch (error) {
        return {
            error: error
        };
    }
}

export async function getOferta() {
    try {
        let url = `${REACT_APP_DOCS_HOST}/api/client/v1/docs/foreign_bank_card?document_type_key=oferta&key=${REACT_APP_BANK_KEY}`;
        const response = await fetch(url, {
            headers: {
                Authorization: `Basic ${REACT_APP_DOCS_KEY}`
            },
            method: 'GET'
        });
        if (response.status >= 400) {
            await sendErrorToBot('🟡FOREIGN CARD REGISTRATION GET OFERTA',
                window.location.href + `\n${response.status}`);
            const res = await response.json();
            return {
                error: res && res.error ? res.error : response.statusText || response.status || 'error'
            }
        }
        return await response.json();
    }
    catch (error) {
        return {
            error: error
        };
    }
}

export async function getMobileOperators(): Promise<any> {
    try {
        return await fetcher(`api/client/v1/mobile_operators`);
    }
    catch (error) {
        return {
            error: error
        };
    }
}

