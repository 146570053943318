import * as React from 'react';
import {useLoaderData} from "react-router-dom";
import {createOrder, getCards, getMobileOperators} from "../api/api";
import sendErrorToBot from '../api/sendError';
import ErrorPage from "./ErrorPage";
import {useEffect, useState} from "react";
import {Store} from "../stores/cards";
import { autoFocusDatepicker } from '../scripts/autoFocusDatepicker';
import { phoneFormatting } from '../scripts/phoneFormatting';
import { checkFields } from '../scripts/checkFields';
import {RegistrationForm} from "./RegistrationForm";

export type PassportPageKeys = 'frontRu' | 'regRu' | 'frontForeign';

const INPUT_IDS_STRING = ['last_name', 'first_name', 'middle_name', 'birthdate', 'mobile_operator',
    'codeword', 'manager_info', 'phone', 'email', 'int_passport_last_name', 'int_passport_first_name',
    'int_passport_date', 'int_passport_issued_by', 'registration_address', 'actual_address', 'family_status'];
const INPUT_IDS_NUMBER = ['int_passport_series', 'int_passport_number'];
const sexes = ['Мужской', 'Женский'];

let currencies: any = ['Доллар', 'Евро'];

export async function registrationLoader() {
    const url = new URL(window.location.href);
    let token = url.searchParams.get('client_token');
    if (!token) {
        const localToken = localStorage.getItem('client_token');
        return !!localToken
            ? window.location.href = `/registration?client_token=${localToken}`
            : { noAccess: true };
    }
    const CardsInst = Store.getInstance();
    let cards = CardsInst.getCurrentCards();
    let bankId = CardsInst.getCurrentBankId();
    let mobileOperators = CardsInst.getMobileOperators();
    if (!cards) {
        const responseCards = await getCards();
        if (!responseCards || !responseCards.result || !responseCards.result.cards
            || responseCards.result.cards.length === 0) {
            sendErrorToBot('🟡FOREIGN CARD REGISTRATION CARDS',
                window.location.href + `${responseCards && responseCards.error ? `\nError: ${JSON.stringify(responseCards.error)}` : ''}`
                + `${responseCards && responseCards.result ? `\nResult: ${JSON.stringify(responseCards.result)}` : ''}`
                + `${navigator && navigator.userAgent ? `\n📱Device: ${navigator.userAgent}` : ''}`);
            return { error: true };
        }
        cards = responseCards.result.cards;
        bankId = responseCards.result.id;
        if (!bankId) {
            sendErrorToBot('🟡FOREIGN CARD REGISTRATION CARDS',
                window.location.href + '\nНЕ ПРИШЕЛ BANK ID'
                + `${responseCards && responseCards.error ? `\nError: ${JSON.stringify(responseCards.error)}` : ''}`
                + `${responseCards && responseCards.result ? `\nResult: ${JSON.stringify(responseCards.result)}` : ''}`
                + `${navigator && navigator.userAgent ? `\n📱Device: ${navigator.userAgent}` : ''}`);
            return { error: true };
        }
        CardsInst.setCards(cards);
        CardsInst.setBankId(bankId);
    }
    if (!mobileOperators) {
        const responseMobileOperators = await getMobileOperators();
        if (!responseMobileOperators || !responseMobileOperators.result
            || responseMobileOperators.result.length === 0) {
            sendErrorToBot('🟡FOREIGN CARD MOBILE OPERATORS',
                window.location.href + `${responseMobileOperators && responseMobileOperators.error ? `\nError: ${JSON.stringify(responseMobileOperators.error)}` : ''}`
                + `${responseMobileOperators && responseMobileOperators.result ? `\nResult: ${JSON.stringify(responseMobileOperators.result)}` : ''}`
                + `${navigator && navigator.userAgent ? `\n📱Device: ${navigator.userAgent}` : ''}`);
            return { error: true };
        }
        mobileOperators = responseMobileOperators.result.map((operator: any) => operator.name);
    }
    CardsInst.setMobileOperators(mobileOperators);
    return { cards, bankId, mobileOperators, token };
}

function RegistrationPage() {
    const loaderData = useLoaderData();
    const [sex, setSex] = useState(sexes[0]);
    const [frontPageRuPassportFile, setFrontPageRuPassportFile] = useState<File | undefined>(undefined);
    const [registrationPageRuPassportFile, setRegistrationPageRuPassportFile] = useState<File | undefined>(undefined);
    const [frontPageForeignPassportFile, setFrontPageForeignPassportFile] = useState<File | undefined>(undefined);
    const [cardId, setCardId] = useState(loaderData && (loaderData as any).cards
        && (loaderData as any).cards[0] ? (loaderData as any).cards[0].id : undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isAgreement, setIsAgreement] = useState(false);
    const [fullRegistrationAddress, setFullRegistrationAddress] = useState('');
    const [fullActualAddress, setFullActualAddress] = useState('');
    const [currency, setCurrency] = useState(loaderData && (loaderData as any).cards
    && (loaderData as any).cards[0] && (loaderData as any).cards[0].currency
        ? (loaderData as any).cards[0].currency.toLowerCase().includes('usd')
            ? currencies[0]
            : (loaderData as any).cards[0].currency.toLowerCase().includes('eur')
                ? currencies[1]
                : undefined
        : undefined);
    const mobileOperators = (loaderData as any).mobileOperators || [];
    const token = (loaderData as any).token || '';

    function setInitialValues() {
        INPUT_IDS_STRING.forEach(id => {
            const value = sessionStorage.getItem(id);
            const elem = document.getElementById(id);
            if (elem && value) {
                if (id === 'birthdate' || 'int_passport_date') {
                    const pseudoElem = document.getElementById(`pseudo_${id}`);
                    if (pseudoElem) {
                        const date = new Date(value);
                        if (date) {
                            (pseudoElem as any).value = `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}.${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}.${date.getFullYear()}`;
                        }
                    }
                }
                (elem as any).value = value;
                if ((id === 'mobile_operator' || id === 'family_status') && !!value) {
                    elem.classList.remove('gray');
                }
            }
            INPUT_IDS_NUMBER.forEach(id => {
                let value: any = sessionStorage.getItem(id);
                const elem = document.getElementById(id);
                if (elem && value) {
                    try {
                        value = Number(value);
                        (elem as any).value = value;
                    }
                    finally {}
                }
            });
        })
        let val: any = sessionStorage.getItem('cardId');
        if (val) {
            try {
                val = Number(val);
                setCardId(val);
            }
            finally {}
        }
        if (loaderData && (loaderData as any).cards && (loaderData as any).cards[0]
            && (loaderData as any).cards[0].currency) {
            let cardCurrency = (loaderData as any).cards[0].currency.toLowerCase();
            if (val && Number(val) && (loaderData as any).cards.find((c: any) => c.id === Number(val))) {
                cardCurrency = (loaderData as any).cards.find((c: any) => c.id === Number(val)).currency.toLowerCase();
            }
            if (!cardCurrency.includes('usd')) {
                currencies = ['Евро'];
                if (!cardCurrency.includes('eur')) {
                    currencies = [];
                }
            } else {
                if (!cardCurrency.includes('eur')) {
                    currencies = ['Доллар'];
                }
            }
        }
        val = sessionStorage.getItem('currency');
        if (val) {
            setCurrency(val);
        }
        val = sessionStorage.getItem('sex');
        if (val) {
            setSex(val);
        }
        val = sessionStorage.getItem('full_registration_address');
        if (val) {
            setFullRegistrationAddress(val);
        }
        val = sessionStorage.getItem('full_actual_address');
        if (val) {
            setFullActualAddress(val);
        }
    }

    useEffect(() => {
        setInitialValues();
        phoneFormatting();
        autoFocusDatepicker();
        window.scrollTo({top: 0, behavior: 'smooth'});
    }, []);

    const isError = !!(loaderData && (loaderData as any).error);
    if (isError) {
        return ErrorPage();
    }

    function handleCardId(id: number) {
        const card = (loaderData as any).cards.find((c: any) => c.id === id);
        if (!card) {
            return;
        }
        let cardCurrency = card.currency.toLowerCase();
        if (!cardCurrency.includes('usd')) {
            currencies = ['Евро'];
            if (!cardCurrency.includes('eur')) {
                currencies = [];
            }
        } else {
            if (!cardCurrency.includes('eur')) {
                currencies = ['Доллар'];
            } else {
                currencies = ['Доллар', 'Евро'];
            }
        }
        if (!currencies.includes(currency)) {
            sessionStorage.setItem('currency', currencies[0]);
            setCurrency(currencies[0]);
        }
        setCardId(id);
    }

    async function onSubmit(event: any) {
        event.preventDefault();
        if (!checkFields()) {
            return;
        }
        if (!frontPageRuPassportFile) {
            const elem = document.getElementById('file-uploader_label__front_page');
            if (elem) {
                elem.classList.add('error');
                window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
            }
            return;
        }
        if (!registrationPageRuPassportFile) {
            const elem = document.getElementById('file-uploader_label__reg_page');
            if (elem) {
                elem.classList.add('error');
                window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
            }
            return;
        }
        if (!frontPageForeignPassportFile) {
            const elem = document.getElementById('file-uploader_label__front_page_foreign');
            if (elem) {
                elem.classList.add('error');
                window.scrollTo({top: document.body.scrollHeight, behavior: 'smooth'});
            }
            return;
        }
        setIsLoading(true);
        const bankId = (loaderData as any).bankId;
        let body: any = {
            bank_id: bankId,
            card_id: cardId,
            data: {
                last_name: {
                    label: 'Фамилия',
                    value: event.target.last_name.value.trim()
                },
                first_name: {
                    label: 'Имя',
                    value: event.target.first_name.value.trim()
                },
                middle_name: {
                    label: 'Отчество',
                    value: event.target.middle_name.value.trim()
                },
                sex: {
                    label: 'Пол',
                    value: sex
                },
                birthdate: {
                    label: 'Дата рождения',
                    value: event.target.birthdate.value
                },
                family_status: {
                    label: 'Семейное положение',
                    value: event.target.family_status.value
                },
                mobile_operator: {
                    label: 'Наименование сотового оператора',
                    value: event.target.mobile_operator.value
                },
                phone: {
                    label: 'Телефон',
                    value: `+7${event.target.phone.value.replaceAll(' ', '')}`
                },
                email: {
                    label: 'Электронная почта',
                    value: event.target.email.value.trim()
                },
                int_passport_first_name: {
                    label: 'Имя (заграничный паспорт)',
                    value: event.target.int_passport_first_name.value.trim()
                },
                int_passport_last_name: {
                    label: 'Фамилия (заграничный паспорт)',
                    value: event.target.int_passport_last_name.value.trim()
                },
                int_passport_series: {
                    label: 'Серия (заграничный паспорт)',
                    value: event.target.int_passport_series.value
                },
                int_passport_number: {
                    label: 'Номер (заграничный паспорт)',
                    value: event.target.int_passport_number.value
                },
                int_passport_date: {
                    label: 'Дата выдачи (заграничный паспорт)',
                    value: event.target.int_passport_date.value
                },
                int_passport_issued_by: {
                    label: 'Кем выдан (заграничный паспорт)',
                    value: event.target.int_passport_issued_by.value
                },
                registration_address: {
                    label: 'Адрес регистрации',
                    value: event.target.registration_address.value.trim() !== fullRegistrationAddress.trim()
                        ? event.target.registration_address.value.trim() + ', ' + fullRegistrationAddress.trim()
                        : event.target.registration_address.value.trim()
                },
                actual_address: {
                    label: 'Желаемый адрес доставки карты',
                    value: event.target.actual_address.value.trim() !== fullActualAddress.trim()
                        ? event.target.actual_address.value.trim() + ', ' + fullActualAddress.trim()
                        : event.target.actual_address.value.trim()
                },
                card_type: {
                    label: 'Тип карты',
                    value: (loaderData as any).cards.find((card: any) => card.id === cardId).name
                },
                currency: {
                    label: 'Валюта счета',
                    value: currency === 'Доллар' ? 'usd' : 'eur'
                },
                codeword: {
                    label: 'Кодовое слово',
                    value: event.target.codeword.value.trim()
                }
            },
            attachments: {
                front_page_of_russian_passport: frontPageRuPassportFile,
                registration_page_of_russian_passport: registrationPageRuPassportFile,
                front_page_of_foreign_passport: frontPageForeignPassportFile
            }
        };
        if (event.target.manager_info.value) {
            body.data['manager_info'] = {
                label: 'ПромоКод / ID менеджера',
                value: event.target.manager_info.value.trim()
            };
        }
        if (token) {
            body['client_token'] = token;
        }
        const res = await createOrder(body);
        setIsLoading(false);
        const returnUrl = localStorage.getItem('return_url');
        if (res && !res.error) {
            alert('Спасибо, Ваша заявка принята. С Вами свяжутся в ближайшее время!');
            sessionStorage.clear();
            if (returnUrl && token) {
                window.location.href = returnUrl;
            } else {
                window.location.href = `/?client_token=${token}`;
            }
        } else {
            sendErrorToBot('🟡FOREIGN CARD REGISTRATION',
                `Token: ${token || 'manager'}\nResponse: ${res ? JSON.stringify(res) : ''}\n`
                + JSON.stringify(body));
            alert(`При отправке произошла ошибка, пожалуйста попробуйте еще раз! ${res && res.error ? res.error : ''}`);
        }
    }

    function uploadFiles(filesList: FileList | null, key: PassportPageKeys) {
        if (filesList) {
            const arr = Array.from(filesList);
            const filteredArr = arr.filter(file => file.type === 'application/pdf');
            if (arr.length !== filteredArr.length) {
                alert('Загрузка документов только в PDF, остальные файлы не будут загружены!');
            }
            let sizeInBytes = filteredArr[0].size;
            let sizeInMB = sizeInBytes / (1024 * 1024);
            if (key === 'frontRu') {
                sizeInMB += registrationPageRuPassportFile ? registrationPageRuPassportFile.size / (1024 * 1024) : 0;
                sizeInMB += frontPageForeignPassportFile ? frontPageForeignPassportFile.size / (1024 * 1024) : 0;
            } else if (key === 'regRu') {
                sizeInMB += frontPageRuPassportFile ? frontPageRuPassportFile.size / (1024 * 1024) : 0;
                sizeInMB += frontPageForeignPassportFile ? frontPageForeignPassportFile.size / (1024 * 1024) : 0;
            } else {
                sizeInMB += frontPageRuPassportFile ? frontPageRuPassportFile.size / (1024 * 1024) : 0;
                sizeInMB += registrationPageRuPassportFile ? registrationPageRuPassportFile.size / (1024 * 1024) : 0;
            }
            if (sizeInMB > 39) {
                alert('Превышен допустимый размер файлов в 40 Мб!');
                return;
            }
            key === 'frontRu' ? setFrontPageRuPassportFile(filteredArr[0])
                : key === 'regRu' ? setRegistrationPageRuPassportFile(filteredArr[0])
                    : setFrontPageForeignPassportFile(filteredArr[0]);
            const elem = document.getElementById(key === 'frontRu' ? 'file-uploader_label__front_page'
                : key === 'regRu' ? 'file-uploader_label__reg_page'
                    : 'file-uploader_label__front_page_foreign');
            if (elem && elem.classList.contains('error')) {
                elem.classList.remove('error');
            }
        }
    }

    function onRemoveFile(key: PassportPageKeys) {
        key === 'frontRu' ? setFrontPageRuPassportFile(undefined)
            : key === 'regRu' ? setRegistrationPageRuPassportFile(undefined)
                : setFrontPageForeignPassportFile(undefined);
    }

    return (
        <RegistrationForm
            isLoading={isLoading}
            isAgreement={isAgreement}
            onSubmit={onSubmit}
            setSex={setSex}
            setCurrency={setCurrency}
            setCardId={handleCardId}
            setIsAgreement={() => setIsAgreement(!isAgreement)}
            setFullRegistrationAddress={(address: string) => setFullRegistrationAddress(address)}
            setFullActualAddress={(address: string) => setFullActualAddress(address)}
            onRemoveFile={onRemoveFile}
            uploadFiles={uploadFiles}
            currencies={currencies}
            currency={currency}
            sexes={sexes}
            sex={sex}
            cards={(loaderData as any).cards}
            cardId={cardId}
            frontPageRuPassportFile={frontPageRuPassportFile}
            registrationPageRuPassportFile={registrationPageRuPassportFile}
            frontPageForeignPassportFile={frontPageForeignPassportFile}
            mobileOperators={mobileOperators}
            fullRegistrationAddress={fullRegistrationAddress}
            fullActualAddress={fullActualAddress}
        />
    );
}

export default RegistrationPage;
